var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.witness-desc")) + " ")]), _vm.witnesses && _vm.witnesses.length < 4 && _vm.userRole != 'Client' ? _c('div', {
    staticClass: "row mb-2 mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('a', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": _vm.openSaksiModal
    }
  }, [_vm._v(_vm._s(_vm.$t("add-witness")))])])]) : _vm._e(), _c('form', {}, [_c('div', {
    staticClass: "table-responsive mt-3 mb-4"
  }, [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("fullname")))]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), ['Staff', 'Super Admin', 'Admin', 'Agent'].includes(_vm.userRole) ? _c('th', {
    staticClass: "text-right",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("action")) + " ")]) : _vm._e()])]), _vm.userRole == 'Client' ? _c('tbody', [_vm.witnesses && _vm.witnesses.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_c('h5', {
    staticClass: "mt-5"
  }, [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.witness1")) + " ")])])]) : _vm._e(), _vm._l(_vm.witnesses, function (witness, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(witness.name))]), _c('td', [_vm._v(_vm._s(witness.ic_number))])]);
  })], 2) : _vm._e(), _vm.userRole != 'Client' ? _c('tbody', [_vm._l(_vm.witnesses, function (witness, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(witness.name))]), _c('td', [_vm._v(_vm._s(witness.ic_number))]), ['Staff', 'Super Admin', 'Admin', 'Agent'].includes(_vm.userRole) ? _c('td', {
      staticClass: "text-right"
    }, [_c('div', {
      staticClass: "btn-group-vertical"
    }, [_c('b-button', {
      staticClass: "btn-sm btn-primary",
      on: {
        "click": function ($event) {
          return _vm.viewWitness(witness, index);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("view")))])], 1)]) : _vm._e()]);
  }), _vm.witnesses && _vm.witnesses.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_c('h5', {
    staticClass: "mt-5"
  }, [_vm._v(_vm._s(_vm.$t("wasiatasas.witness2")))]), _vm.userRole == 'Client' ? _c('p', [_c('small', [_c('i', [_vm._v(" " + _vm._s(_vm.$t("wasiatasas.witness3")) + " ")])])]) : _vm._e()])]) : _vm._e()], 2) : _vm._e()])])])]), _c('b-modal', {
    ref: "modalsaksi",
    staticClass: "modal fade",
    attrs: {
      "id": "modal-1",
      "title": "Tambah Saksi",
      "ok-title": "Simpan",
      "cancel-title": "Batal",
      "no-enforce-focus": true
    },
    on: {
      "ok": _vm.addSaksi,
      "cancel": _vm.hideModalSaksi
    }
  }, [_c('AddSaksi', {
    ref: "addSaksiComponent",
    attrs: {
      "form": _vm.formSaksi
    },
    on: {
      "formAddPostcode": _vm.eventHandler
    }
  }), _c('div', {
    staticClass: "pl-3 pr-3"
  }, [_vm.formSaksi.newWitness ? _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "exampleSelect1"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.signature")) + " ")]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('div', {
    staticClass: "card",
    staticStyle: {
      "width": "100%",
      "display": "inline-block"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "relative",
      "height": "300px",
      "width": "100%"
    }
  }, [_c('VueSignaturePad', {
    ref: "signaturePad",
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "height": "300px",
      "width": "100%",
      "options": {
        onBegin: function () {
          _vm.$refs.signaturePad.resizeCanvas();
        }
      }
    }
  }), _c('label', {
    staticStyle: {
      "z-index": "0",
      "position": "absolute",
      "right": "0px",
      "left": "0px",
      "top": "45%",
      "color": "#b6b6b4",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("sign-here")) + " ")]), _c('a', {
    staticClass: "btn btn-primary",
    staticStyle: {
      "position": "absolute",
      "right": "5px",
      "top": "5px",
      "z-index": "2"
    },
    on: {
      "click": _vm.undo
    }
  }, [_c('img', {
    attrs: {
      "src": "/images/icon/rubber.svg",
      "height": "20",
      "alt": ""
    }
  })])], 1)])]) : _vm._e()])], 1)], 1)])]), !_vm.pengesahan ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_vm.userRole == 'Staff' ? _c('div', {
    staticClass: "form-group"
  }, [_vm.wasiat && _vm.wasiat.status != 2 ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onSubmitStaffOnly
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("complete-without-digital-sign")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "form-group"
  }, [_vm.wasiat && _vm.wasiat.status != 2 ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.onsubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("done")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()]) : _vm._e(), _vm.wasiat && _vm.wasiat.status == 2 ? _c('button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.nextPage
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("done")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()]) : _vm._e()])])]) : _vm._e(), _c('b-modal', {
    ref: "formAddPostcode",
    staticClass: "modal fade",
    attrs: {
      "id": "formAddPostcode",
      "hide-footer": ""
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("add-postcode")) + " ")])]), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control savePostcode",
    attrs: {
      "type": "number",
      "name": "userstate"
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control saveCity",
    attrs: {
      "type": "text",
      "name": "userstate"
    }
  })]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    staticClass: "form-control saveState",
    attrs: {
      "type": "text",
      "name": "userstate"
    }
  })])]), _c('b-button', {
    staticClass: "mt-3",
    attrs: {
      "variant": "outline-danger",
      "block": ""
    },
    on: {
      "click": _vm.hideModal
    }
  }, [_vm._v(_vm._s(_vm.$t("close")))])], 1), _c('witness-details', {
    ref: "viewWitnessModal",
    attrs: {
      "isEditable": true,
      "isDeletable": true,
      "signature": _vm.signaturePath,
      "getWitnessesMethodName": "getWitnesses"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }